<template>
  <div>
    <div :class="path_arr == 'path_array1' ? '_screen_box_' : 'screen_media_box'">
      <ul class="flex ul1">
        <li :class="category_id == item.id ? 'active1' : ''" v-for="( item, index ) in  media_nav" :key="index"
            @click="nav_route(index)">
          {{ item.title }}
        </li>
      </ul>
      <!-- <Nav title="1" /> -->
      <div class="type2 flex" v-if="form.portal_list.length != 0">
        <p>{{ category_id == 1 ? '门户类型' : category_id == 2 ? '所属平台' : '平台类型' }}：</p>
        <ul class="flex ul2">
          <li :class="$store.state.media_screen_data.date_form.portal_id == - 1 ? 'active' : ''"
              @click="tab('portal_id', -1)">不限
          </li>
          <li :class="$store.state.media_screen_data.date_form.portal_id == index ? 'active' : ''"
              v-for="( item, index ) in  form.portal_list " :key="index" @click="tab('portal_id', index)">
            {{ item }}
          </li>
        </ul>
      </div>
      <div class="type2 flex" v-if="form.industry_list.length != 0">
        <p>行业类型：</p>
        <ul class="flex ul2">
          <li :class="$store.state.media_screen_data.date_form.industry_id == -1 ? 'active' : ''"
              @click="tab('industry_id', -1)">不限
          </li>
          <li :class="$store.state.media_screen_data.date_form.industry_id == index ? 'active' : ''"
              v-for="( item, index ) in  form.industry_list " :key="index" @click="tab('industry_id', index)">
            {{ item }}
          </li>
        </ul>
      </div>
      <div class="type2 flex" v-if="form.area_list.length != 0">
        <p>{{ $store.state.media_screen_data.date_form.category_id == 6 ? '受众区域' : '所属区域' }}：</p>
        <ul class="flex ul2">
          <li :class="$store.state.media_screen_data.date_form.area_id == -1 ? 'active' : ''"
              @click="tab('area_id', -1)">不限
          </li>
          <li :class="$store.state.media_screen_data.date_form.area_id == index ? 'active' : ''"
              v-for="( item, index ) in  form.area_list " :key="index" @click="tab('area_id', index)">
            {{ item }}
          </li>
        </ul>
      </div>
      <div class="type2 flex" v-if="form.fans_list.length != 0">
        <p>参考粉丝数：</p>
        <ul class="flex ul2">
          <li :class="$store.state.media_screen_data.date_form.fans_num == -1 ? 'active' : ''"
              @click="tab('fans_num', -1)">不限
          </li>
          <li :class="$store.state.media_screen_data.date_form.fans_num == index ? 'active' : ''"
              v-for="( item, index ) in  form.fans_list " :key="index" @click="tab('fans_num', index)">
            {{ item }}
          </li>
        </ul>
      </div>
      <div class="type2 flex" v-if="form.views_list.length != 0 && category_id != 7">
        <p>平均浏览量：</p>
        <ul class="flex ul2">
          <li :class="$store.state.media_screen_data.date_form.views == -1 ? 'active' : ''"
              @click="tab('views', -1)">不限
          </li>
          <li :class="$store.state.media_screen_data.date_form.views == index ? 'active' : ''"
              v-for="( item, index ) in  form.views_list " :key="index" @click="tab('views', index)">
            {{ item }}
          </li>
        </ul>
      </div>
      <!--小红书-图文点击量、视频点击量筛选      -->
      <div class="type2 flex" v-if="form.image_text_views_list.length != 0 && category_id === 6 ">
        <p>图文点击量：</p>
        <ul class="flex ul2">
          <li :class="$store.state.media_screen_data.date_form.image_text_views == -1 ? 'active' : ''"
              @click="tab('image_text_views', -1)">
            不限
          </li>
          <li :class="$store.state.media_screen_data.date_form.image_text_views == index ? 'active' : ''"
              v-for="( item, index ) in  form.image_text_views_list " :key="index"
              @click="tab('image_text_views', index)">
            {{ item }}
          </li>
        </ul>
      </div>
      <div class="type2 flex" v-if="form.video_price_views_list.length != 0 && category_id === 6">
        <p>视频点击量：</p>
        <ul class="flex ul2">
          <li :class="$store.state.media_screen_data.date_form.video_price_views == -1 ? 'active' : ''"
              @click="tab('video_price_views', -1)">不限
          </li>
          <li :class="$store.state.media_screen_data.date_form.video_price_views == index ? 'active' : ''"
              v-for="( item, index ) in  form.video_price_views_list " :key="index"
              @click="tab('video_price_views', index)">
            {{ item }}
          </li>
        </ul>
      </div>

      <div class="type2 flex"
           v-if="form.inlet_category.length != 0 && category_id !== 3 && category_id !== 2">
        <p>收录效果：</p>
        <ul class="flex ul2">
          <li :class="$store.state.media_screen_data.date_form.inlet_category == -1 ? 'active' : ''"
              @click="tab('inlet_category', -1)">不限
          </li>
          <li :class="$store.state.media_screen_data.date_form.inlet_category == index ? 'active' : ''"
              v-for="( item, index ) in  form.inlet_category " :key="index"
              @click="tab('inlet_category', index)">
            {{ item }}
          </li>
        </ul>
      </div>
      <div class="type2 flex"
           v-if="$store.state.media_screen_data.date_form.category_id == 1 || $store.state.media_screen_data.date_form.category_id == 2 || $store.state.media_screen_data.date_form.category_id == 3 || $store.state.media_screen_data.date_form.category_id == 7 || $store.state.media_screen_data.date_form.category_id == 99 && form.price_range.length != 0">
        <p>价格区间：</p>
        <ul class="flex ul2">
          <li :class="$store.state.media_screen_data.date_form.price_id == -1 ? 'active' : ''"
              @click="tab('price_id', -1)">不限
          </li>
          <li :class="$store.state.media_screen_data.date_form.price_id == index ? 'active' : ''"
              v-for="( item, index ) in  form.price_range " :key="index" @click="tab('price_id', index)">
            {{ item }}
          </li>
        </ul>
      </div>
      <div class="type2 flex" v-if="form.price_range.length != 0 && category_id == 5">
        <p>直发价格：</p>
        <ul class="flex ul2">
          <li :class="$store.state.media_screen_data.date_form.price_id === -1 ? 'active' : ''"
              @click="tab('price_id', '')">不限
          </li>
          <li :class="$store.state.media_screen_data.date_form.price_id === index ? 'active' : ''"
              v-for="( item, index ) in  form.price_range " :key="index" @click="tab('price_id', index)">
            {{ item }}
          </li>
        </ul>
      </div>
      <div class="type2 flex"
           v-if="$store.state.media_screen_data.date_form.category_id == 5 && form.price_range.length != 0">
        <p>转发价格：</p>
        <ul class="flex ul2">
          <li :class="$store.state.media_screen_data.date_form.forward_price_id == -1 ? 'active' : ''"
              @click="tab('forward_price_id', -1)">不限
          </li>
          <li :class="$store.state.media_screen_data.date_form.forward_price_id == index ? 'active' : ''"
              v-for="( item, index ) in  form.price_range " :key="index"
              @click="tab('forward_price_id', index)">
            {{ item }}
          </li>
        </ul>
      </div>
      <div class="type2 flex" v-if="form.official_list.length != 0 && category_id != 5">
        <p>是否官方：</p>
        <ul class="flex ul2">
          <li :class="$store.state.media_screen_data.date_form.is_official === '' ? 'active' : ''"
              @click="tab('is_official', '')">不限
          </li>
          <li :class="$store.state.media_screen_data.date_form.is_official === index ? 'active' : ''"
              v-for="( item, index ) in  form.official_list " :key="index" @click="tab('is_official', index)">
            {{ item }}
          </li>
        </ul>
      </div>
      <div class="type2 flex" v-if="form.price_range.length != 0 && category_id == 4">
        <p>头条价格：</p>
        <ul class="flex ul2">
          <li :class="$store.state.media_screen_data.date_form.headline_price_id === -1 ? 'active' : ''"
              @click="tab('headline_price_id', -1)">不限
          </li>
          <li :class="$store.state.media_screen_data.date_form.headline_price_id === index ? 'active' : ''"
              v-for="( item, index ) in  form.price_range " :key="index"
              @click="tab('headline_price_id', index)">
            {{ item }}
          </li>
        </ul>
      </div>
      <div class="type2 flex" v-if="form.price_range.length != 0 && category_id == 4">
        <p>普条价格：</p>
        <ul class="flex ul2">
          <li :class="$store.state.media_screen_data.date_form.price_id === -1 ? 'active' : ''"
              @click="tab('price_id', -1)">不限
          </li>
          <li :class="$store.state.media_screen_data.date_form.price_id === index ? 'active' : ''"
              v-for="( item, index ) in form.price_range" :key="index" @click="tab('price_id', index)">
            {{ item }}
          </li>
        </ul>
      </div>

      <div class="type2 flex" v-if="form.is_v_auth.length != 0">
        <p>V认证：</p>
        <ul class="flex ul2">
          <li :class="$store.state.media_screen_data.date_form.is_v_auth === '' ? 'active' : ''"
              @click="tab('is_v_auth', '')">不限
          </li>
          <li :class="$store.state.media_screen_data.date_form.is_v_auth === index ? 'active' : ''"
              v-for="( item, index ) in  form.is_v_auth " :key="index" @click="tab('is_v_auth', index)">
            {{ item }}
          </li>
        </ul>
      </div>
      <!-- 自媒体v认证 -->
      <div class="type2 flex" v-if="form.is_v_auth_list.length != 0">
        <p>V认证：</p>
        <ul class="flex ul2">
          <li :class="$store.state.media_screen_data.date_form.is_v_auth === '' ? 'active' : ''"
              @click="tab('is_v_auth', '')">不限
          </li>
          <li :class="$store.state.media_screen_data.date_form.is_v_auth === index ? 'active' : ''"
              v-for="( item, index ) in  form.is_v_auth_list " :key="index" @click="tab('is_v_auth', index)">
            {{ item }}
          </li>
        </ul>
      </div>
      <div class="type2 flex" v-if="form.image_text_price_range.length != 0">
        <p>图文价格区间：</p>
        <ul class="flex ul2">
          <li :class="$store.state.media_screen_data.date_form.image_text_price === -1 ? 'active' : ''"
              @click="tab('image_text_price', -1)">不限
          </li>
          <li :class="$store.state.media_screen_data.date_form.image_text_price === index ? 'active' : ''"
              v-for="( item, index ) in  form.image_text_price_range " :key="index"
              @click="tab('image_text_price', index)">
            {{ item }}
          </li>
        </ul>
      </div>
      <div class="type2 flex" v-if="form.image_text_price_range.length != 0">
        <p>视频价格区间：</p>
        <ul class="flex ul2">
          <li :class="$store.state.media_screen_data.date_form.video_price === '' ? 'active' : ''"
              @click="tab('video_price', '')">不限
          </li>
          <li :class="$store.state.media_screen_data.date_form.video_price === index ? 'active' : ''"
              v-for="( item, index ) in  form.image_text_price_range " :key="index"
              @click="tab('video_price', index)">
            {{ item }}
          </li>
        </ul>
      </div>
      <div class="type2 flex" v-if="form.audience_sex_list.length != 0">
        <p>受众性别：</p>
        <ul class="flex ul2">
          <li :class="$store.state.media_screen_data.date_form.audience_sex === -1 ? 'active' : ''"
              @click="tab('audience_sex', -1)">不限
          </li>
          <li :class="$store.state.media_screen_data.date_form.audience_sex === index ? 'active' : ''"
              v-for="( item, index ) in  form.audience_sex_list " :key="index"
              @click="tab('audience_sex', index)">
            {{ item }}
          </li>
        </ul>
      </div>
      <div class="type2 flex" v-if="form.audience_age_list.length != 0">
        <p>受众年龄：</p>
        <ul class="flex ul2">
          <li :class="$store.state.media_screen_data.date_form.audience_age === -1 ? 'active' : ''"
              @click="tab('audience_age', -1)">不限
          </li>
          <li :class="$store.state.media_screen_data.date_form.audience_age === index ? 'active' : ''"
              v-for="( item, index ) in  form.audience_age_list " :key="index"
              @click="tab('audience_age', index)">
            {{ item }}
          </li>
        </ul>
      </div>
      <div class="type2 flex" v-if="!form.special_list.length != 0 && category_id !== 99">
        <p>特殊类目：</p>
        <ul class="flex ul2">
          <li :class="!$store.state.media_screen_data.date_form.spce_title ? 'active' : ''"
              @click="spec_tab('', 0)">不限
          </li>
          <li
              :class="$store.state.media_screen_data.date_form.spce_title === item && ( $store.state.media_screen_data.date_form.is_pub_image === 1 || $store.state.media_screen_data.date_form.is_pub_weekend === 1 || $store.state.media_screen_data.date_form.is_pub_url === 1 || $store.state.media_screen_data.date_form.is_pub_video === 1 || $store.state.media_screen_data.date_form.is_pub_contact === 1 ) ? 'active' : ''"

              v-for="( item, index ) in  form.special_list " :key="index" @click="spec_tab(item, index)"
          >
            {{ item }}
          </li>
        </ul>
      </div>

      <div class="type3 flex">
        <p>排序：</p>
        <ul class="flex ul2">
          <li @click="screenFn('')"
              :class="$store.state.media_screen_data.date_form.order == '' && $store.state.media_screen_data.date_form.sort == '' ? 'active' : ''">
            默认
          </li>
          <!-- 套餐价格排序 -->
          <li @click="screenFn('price')"
              :id="$store.state.media_screen_data.date_form.sort == 'price' && $store.state.media_screen_data.date_form.order == 'asc' ? 'activeTrue' : $store.state.media_screen_data.date_form.sort == 'price' && $store.state.media_screen_data.date_form.order == 'desc' ? 'activeFalse' : ''"
              v-if="category_id === 99">
            价格
          </li>
          <!-- 媒介价格排序 -->
          <li @click="screenFn('member_price')"
              :id="$store.state.media_screen_data.date_form.sort == 'member_price' && $store.state.media_screen_data.date_form.order == 'asc' ? 'activeTrue' : $store.state.media_screen_data.date_form.sort == 'member_price' && $store.state.media_screen_data.date_form.order == 'desc' ? 'activeFalse' : ''"
              v-if="category_id == 1 || category_id == 2 || category_id === 3 || category_id === 7">
            价格
          </li>
          <li :id="$store.state.media_screen_data.date_form.sort == 'weight' && $store.state.media_screen_data.date_form.order == 'asc' ? 'activeTrue' : $store.state.media_screen_data.date_form.sort == 'weight' && $store.state.media_screen_data.date_form.order == 'desc' ? 'activeFalse' : ''"
              @click="screenFn('weight')" v-if="category_id == 1">
            电脑权重
          </li>
          <li :id="$store.state.media_screen_data.date_form.sort == 'm_weight' && $store.state.media_screen_data.date_form.order == 'asc' ? 'activeTrue' : $store.state.media_screen_data.date_form.sort == 'm_weight' && $store.state.media_screen_data.date_form.order == 'desc' ? 'activeFalse' : ''"
              @click="screenFn('m_weight')" v-if="category_id == 1">
            移动权重
          </li>
          <li :id="$store.state.media_screen_data.date_form.sort == 'member_headline_price' && $store.state.media_screen_data.date_form.order == 'asc' ? 'activeTrue' : $store.state.media_screen_data.date_form.sort == 'member_headline_price' && $store.state.media_screen_data.date_form.order == 'desc' ? 'activeFalse' : ''"
              @click="screenFn('member_headline_price')" v-if="category_id == 4">
            头条价格
          </li>
          <li :id="$store.state.media_screen_data.date_form.sort == 'member_price' && $store.state.media_screen_data.date_form.order == 'asc' ? 'activeTrue' : $store.state.media_screen_data.date_form.sort == 'member_price' && $store.state.media_screen_data.date_form.order == 'desc' ? 'activeFalse' : ''"
              @click="screenFn('member_price')" v-if="category_id == 4">
            移动权重
          </li>
          <li :id="$store.state.media_screen_data.date_form.sort == 'medium_price' && $store.state.media_screen_data.date_form.order == 'asc' ? 'activeTrue' : $store.state.media_screen_data.date_form.sort == 'medium_price' && $store.state.media_screen_data.date_form.order == 'desc' ? 'activeFalse' : ''"
              @click="screenFn('medium_price')" v-if="category_id == 5">
            直发价格
          </li>
          <li :id="$store.state.media_screen_data.date_form.sort == 'member_forward_price' && $store.state.media_screen_data.date_form.order == 'asc' ? 'activeTrue' : $store.state.media_screen_data.date_form.sort == 'member_forward_price' && $store.state.media_screen_data.date_form.order == 'desc' ? 'activeFalse' : ''"
              @click="screenFn('member_forward_price')" v-if="category_id == 5">
            转发价格
          </li>
          <li :id="$store.state.media_screen_data.date_form.sort == 'member_image_text_price' && $store.state.media_screen_data.date_form.order == 'asc' ? 'activeTrue' : $store.state.media_screen_data.date_form.sort == 'member_image_text_price' && $store.state.media_screen_data.date_form.order == 'desc' ? 'activeFalse' : ''"
              @click="screenFn('member_image_text_price')" v-if="category_id == 6">
            图文价格
          </li>
          <li :id="$store.state.media_screen_data.date_form.sort == 'member_video_price' && $store.state.media_screen_data.date_form.order == 'asc' ? 'activeTrue' : $store.state.media_screen_data.date_form.sort == 'member_video_price' && $store.state.media_screen_data.date_form.order == 'desc' ? 'activeFalse' : ''"
              @click="screenFn('member_video_price')" v-if="category_id == 6">
            视频价格
          </li>
        </ul>
      </div>
      <div class="type4 flex">
        <p class="type_title_n" style="width: 90px;margin-left: 10px">
          {{ category_id == 99 ? '套餐名称：' : '媒介名称：' }}
        </p>
        <el-input @keyup.enter.native="screen_confirm" v-model="$store.state.media_screen_data.date_form.title"
                  placeholder="请输入编号或名称"></el-input>

        <div class="flex link_box" v-if="category_id !== 3 && category_id !==6 && category_id !==99">
          <p>可带链接：</p>
          <el-select v-model="$store.state.media_screen_data.date_form.is_pub_link" placeholder="请选择">
            <el-option v-for="( item, index ) in  form.linkList " :key="index" :label="item.name"
                       :value="item.id">
            </el-option>
          </el-select>
        </div>
        <div class="flex link_box" v-if="category_id !==6 && category_id !==99 ">
          <p>可发联系方式：</p>
          <el-select v-model="$store.state.media_screen_data.date_form.is_pub_contact" placeholder="请选择">
            <el-option v-for="( item, index ) in  form.linkList " :key="index" :label="item.name"
                       :value="item.id">
            </el-option>
          </el-select>
        </div>
        <p class="type_title_n" style="width: 90px;margin-left: 10px">
          备注搜索：
        </p>
        <el-input @keyup.enter.native="screen_confirm" v-model="$store.state.media_screen_data.date_form.remark"
                  placeholder="请输入备注关键字"></el-input>
        <button class="sf_but" type="primary" @click="screen_confirm">筛选</button>
        <button class="sf_but2" type="primary" @click="reset_screen">重置筛选</button>
        <button v-if="userInfo" :class="media_screen_data.date_form.collect ? 'set_menu_show' : 'set_menu_none'"
                type="primary"
                @click="set_collect">我的收藏
        </button>
        <mediaWhiteList/>

      </div>
    </div>
    <div class="flex title_box" :class="path_arr == 'path_array1' ? '' : 'screen_media_box'">
      <!-- <ul class="SetMenu_tab flex">
          <li @click="set_collect(0)"
              :class="$store.state.media_screen_data.date_form.collect == 0 ? 'SetMenu_Show' : ''">
              全部媒介
          </li>
          <li @click="set_collect(1)"
              :class="$store.state.media_screen_data.date_form.collect == 1 ? 'SetMenu_Show' : ''">
              我的收藏
          </li>
      </ul> -->
    </div>
  </div>
</template>

<script>
import {mapMutations, mapState} from 'vuex'

export default {
  components: {},
  props: {
    category_id: Number,
    path_arr: String,
  },
  computed: {
    ...mapState(['media_screen_data']),
    ...mapMutations(['SET_DATE']),

  },
  mounted() {
    // this.reset_screen()
    //读取本地存储中用户的登录状态
    this.userInfo = localStorage.getItem('user_info')
    // console.log('用户状态',this.userInfo)


    let path_url_arr = {
      path_array1: ['/user/user_media/News', '/user/user_media/UserWeMedia', '/user/user_media/UserPaperMedia', '/user/user_media/UserOfficialAccounts', '/user/user_media/UserWb', '/user/user_media/UserRedBook', '/user/user_media/UserShortVideo'],
      path_array2: ['/media/News', '/media/WeMedia', '/media/PaperMedia', '/media/OfficialAccounts', '/media/wb', '/media/RedBook', '/media/ShortVideo', '/media/SetMedia'],
    }
    this.$store.state.media_screen_data.date_form.category_id = this.$props.category_id
    let url = this.path_arr == 'path_array1' ? '/api/medium_category/list' : '/api/medium/init'
    // 获取媒介分类
    this.curlGet(url).then(res => {
      if (res.data.code) {
        // this.media_nav = res.data.data
        if (this.$props.path_arr == 'path_array1') {
          res.data.data.forEach((item, index) => {
            this.media_nav.push({
              id: item.id,
              title: item.title,
            })
          })
        } else if (this.path_arr == 'path_array2') {
          for (const key in res.data.data) {
            this.media_nav.push({
              id: res.data.data[key].id,
              title: res.data.data[key].title,

            })
          }
        }
        path_url_arr[this.path_arr].forEach((item, index) => {
          this.media_nav[index].path_url = item
        })
      }
    })
    this.curlGet('/api/medium/init').then(res => {
      // console.log('请求结果', res)
      if (res.data.code) {
        let mediaNav = res.data.data
        for (let i in mediaNav) {
          if (mediaNav[i].id == this.$store.state.media_screen_data.date_form.category_id) {
            // 门户类型
            if (mediaNav[i].portal_list) {
              this.form.portal_list = mediaNav[i].portal_list

            }
            if (mediaNav[i].industry_list) {
              this.form.industry_list = mediaNav[i].industry_list
            }
            // 地区
            if (mediaNav[i].area_list) {
              this.form.area_list = mediaNav[i].area_list
            }
            // 收录效果
            if (mediaNav[i].inlet_category) {
              this.form.inlet_category = mediaNav[i].inlet_category
            }
            // 是否官方
            if (mediaNav[i].official_list) {
              this.form.official_list = mediaNav[i].official_list
            }
            // 价格区间 || 普条价格
            if (mediaNav[i].price_range) {
              this.form.price_range = mediaNav[i].price_range
            }
            // 图文价格
            if (mediaNav[i].image_text_price_range) {
              this.form.image_text_price_range = mediaNav[i].image_text_price_range
            }
            // 视频价格
            if (mediaNav[i].video_price_range) {
              this.form.video_price_range = mediaNav[i].video_price_range
            }
            // 参考粉丝
            if (mediaNav[i].fans_list) {
              this.form.fans_list = mediaNav[i].fans_list
            }
            // 平均阅读量
            if (mediaNav[i].views_list) {
              this.form.views_list = mediaNav[i].views_list
            }

            //小红书 图文点击量
            if (mediaNav[i].image_text_views_list) {
              this.form.image_text_views_list = mediaNav[i].image_text_views_list
            }
            //小红书 视频点击量
            if (mediaNav[i].video_price_views_list) {
              this.form.video_price_views_list = mediaNav[i].video_price_views_list
            }
            // 特殊类目
            if (mediaNav[i].special_list) {
              this.form.special_list = mediaNav[i].special_list
            }
            // V认证
            if (mediaNav[i].is_v_auth) {
              this.form.is_v_auth = mediaNav[i].is_v_auth
            }
            if (mediaNav[i].is_v_auth_list) {
              this.form.is_v_auth_list = mediaNav[i].is_v_auth_list
            }

            // 年龄
            if (mediaNav[i].audience_age_list) {
              this.form.audience_age_list = mediaNav[i].audience_age_list
            }
            // 性别
            if (mediaNav[i].audience_sex_list) {
              this.form.audience_sex_list = mediaNav[i].audience_sex_list
            }
          }
        }
        this.screen_confirm()
      }
    })

  },
  data() {
    return {
      media_nav: [],
      form: {
        // 平台类型
        portal_list: [],
        // 频道类型
        industry_list: [],
        // 地区
        area_list: [],
        // 收录效果
        inlet_category: [],
        // 是否官方
        official_list: [],
        // 价格区间
        price_range: [],
        // 特殊类目
        special_list: [],
        // 粉丝量
        fans_list: [],
        // 平均阅读量
        views_list: [],
        // 图文点击量
        image_text_views_list: [],
        //视频点击量
        video_price_views_list: [],
        // 图文价格
        image_text_price_range: [],
        // 视频价格
        video_price_range: [],
        // v认证
        is_v_auth: [],
        // 自媒体V认证
        is_v_auth_list: [],
        // 年龄筛选
        audience_age_list: [],
        // 性别筛选
        audience_sex_list: [],
        priceStatus: [{
          id: 0,
          name: '默认价格'
        },
          {
            id: 'desc',
            name: '价格降序'
          },
          {
            id: 'asc',
            name: '价格升序'
          }
        ],
        // 可发链接
        linkList: [{
          id: 0,
          name: '否'
        }, {
          id: 1,
          name: '是'
        }],
      },
      userInfo: {}
    }
  },

  methods: {
    tab(type, id) {
      console.log('点击', type, id)
      this.$store.state.media_screen_data.date_form[type] = id
      this.screen_confirm()
    },
    spec_tab(item, index) {
      // 重置
      this.$store.state.media_screen_data.date_form.is_pub_image = 0
      this.$store.state.media_screen_data.date_form.is_pub_weekend = ''
      this.$store.state.media_screen_data.date_form.is_pub_url = ''
      this.$store.state.media_screen_data.date_form.is_pub_video = 0
      this.$store.state.media_screen_data.date_form.is_pub_weekend = 0
      //赋值
      this.$store.state.media_screen_data.date_form.spce_title = item
      if (index !== -1) {
        this.$store.state.media_screen_data.date_form[index] = 1
      }
      this.screen_confirm()
      this.$forceUpdate()//强制刷新
    },
    // 价格 电脑权重 移动权重筛选
    screenFn(item) {
      // 赋值给sort类型
      this.$store.state.media_screen_data.date_form.sort = item
      if (this.$store.state.media_screen_data.date_form.order == '' && item || this.$store.state.media_screen_data.date_form.order == 'desc' && item) {
        this.$store.state.media_screen_data.date_form.order = 'asc'
      } else if (this.$store.state.media_screen_data.date_form.order == 'asc' && item) {
        this.$store.state.media_screen_data.date_form.order = 'desc'
      } else if (!item) {
        // 如果用户点击的默认 清除order排序状态
        this.$store.state.media_screen_data.date_form.order = ''
      }
      this.screen_confirm()
    },
    set_collect() {
      if (this.$store.state.media_screen_data.date_form.collect) {
        this.$store.state.media_screen_data.date_form.collect = 0
      } else {
        this.$store.state.media_screen_data.date_form.collect = 1
      }
      this.screen_confirm()
    },
    reset_screen(type) {
      this.$store.commit('SET_DATE', {
        // 媒体类别id
        category_id: this.$props.category_id,
        // 搜索标题
        title: '',
        //排序-方式
        order: '',
        //排序-类别
        sort: '',
        //是否收藏
        collect: 0,
        // 平台--门户id
        portal_id: -1,
        // 行业id
        industry_id: -1,
        // 地区id
        area_id: -1,
        //收录类型id
        inlet_category: -1,
        // 头条价格
        headline_price_id: -1,
        // 价格id || 普条价格 -- 小红书图文价格
        price_id: -1,
        // 参考粉丝id
        fans_num: -1,
        // 平均阅读量
        views: -1,
        //小红书图文点击量
        image_text_views: -1,
        //小红书视频点击量
        video_price_views: -1,
        // 头条价格
        member_headline_price: -1,
        // 转发价格
        forward_price_id: -1,
        // 图文价格
        image_text_price: -1,
        // 视频价格
        video_price: '',
        // 是否认证
        is_v_auth: '',
        // 是否官方
        is_official: '',
        // 焦点图
        is_pub_image: '',
        // 可发链接
        is_pub_url: '',
        // 可带链接
        is_pub_link: '',
        // 可发视频
        is_pub_video: '',
        // 可发联系方式
        is_pub_contact: '',
        // 周末可发
        is_pub_weekend: '',
        // 受众性别
        audience_sex: -1,
        // 受众年龄
        audience_age: -1,
        //   备注搜索
        remark: '',
      })
      if (type !== 2) {
        this.$emit('screen_confirm')
      }
    },
    screen_confirm() {
      this.$emit('screen_confirm')
    },
    nav_route(index) {
      console.log(index)
      // 区分类型,如果是跳转路由,如果是2则重置筛选条件
      this.$router.push({
        path: this.media_nav[index].path_url
      })
      this.reset_screen(2)

    }
  },
}
</script>

<style lang=scss scoped>
@import '@/scss/user_media'
</style>